/**
 * JAVASCRIPT DEVELOPER DOCUMENTATION
 *
 * Symmetry is a powerful and customizable theme designed for large-scale e-commerce stores. Built
 * using Web Components, it offers a highly modular architecture that makes customization and
 * maintenance easier than ever. In addition, Symmetry is optimized for speed, ensuring that your
 * store runs as fast as possible to provide your customers with a seamless shopping experience.
 *
 * If you would like to add your own JS to Symmetry, we recommend using this file and referencing
 * it using Theme Settings > Advanced > Custom HTML.
 *
 * As a brief overview, Symmetry:
 *  - Broadcasts many JS events.
 *  - Is built using Web Components.
 *  - Leverages 'code splitting' for some features.
 *  - Is completely custom built (no JS libraries)
 *  - Has a number of JS utilities.
 *
 *
 *
 * =================================================================================================
 * Custom JavaScript Events
 * =================================================================================================
 *
 * Symmetry broadcasts many custom events for ease of extensibility, detailed in this section.
 *
 * When in the Theme Editor, the details of each custom event will be logged out in the Dev Tools
 * console everytime it is triggered.
 *
 * Events are named in the following convention: ["on/dispatch"]:[subject]:[action] (where
 * 'dispatch' will trigger an event to occur, and 'on' indicates an event has occurred).
 *
 * All 'Return data' detailed in this section is returned within the 'event.detail' object.
 *
 * The available events are:
 *  1.  on:variant:change
 *  2.  on:cart:add
 *  3.  on:cart:error
*   4.  on:cart:after-merge
 *  5.  on:cart-drawer:before-open
 *  6.  on:cart-drawer:after-open
 *  7.  on:cart-drawer:after-close
 *  8.  on:quickbuy:before-open
 *  9.  on:quickbuy:after-open
 *  10. on:quickbuy:after-close
 *  11. dispatch:cart-drawer:open
 *  12. dispatch:cart-drawer:refresh
 *  13. dispatch:cart-drawer:close
 *  14. on:debounced-resize
 *  15. on:breakpoint-change
 *
 * -------------------------------------------------------------------------------------------------
 * 1) on:variant:change
 * -------------------------------------------------------------------------------------------------
 * Fires whenever a variant is selected (e.g. Product page, Quickbuy, Featured Product etc).
 *
 * How to listen:
 * document.addEventListener('on:variant:change', (event) => {
 *  // your code here
 * });
 *
 * Returned data:
 *  - form: the product form content
 *  - variant: the selected variant object
 *  - allVariants: an array of all variants
 *  - selectedOptions: an array of currently selected options (e.g. ['Blue', 'Large'])
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 2) on:cart:add
 * -------------------------------------------------------------------------------------------------
 * Fires when a variant has been added to the cart, where it didn't exist in the cart before. This
 * event does not fire when the added variant was already in the cart.
 *
 * How to listen:
 * document.addEventListener('on:cart:add', (event) => {
 *   // your code here
 * });
 *
 * Returned data:
 *   - variantId: id of the variant that was just added to the cart
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 3) on:cart:error
 * -------------------------------------------------------------------------------------------------
 * Fires when an action related to the cart has failed, for example adding too much quantity of an
 * item to the cart.
 *
 * How to listen:
 * document.addEventListener('on:cart:error', (event) => {
 *   // your code here
 * });
 *
 * Returned data:
 *   - error: the error message
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 4) on:cart:after-merge
 * -------------------------------------------------------------------------------------------------
 * Fires after a list of cart items has finished being dynamically updated after a cart change.
 *
 * How to listen:
 * document.addEventListener('on:cart:after-merge', (event) => {
 *   // your code here
 * });
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 5) on:cart-drawer:before-open
 * -------------------------------------------------------------------------------------------------
 * Fires before the cart drawer opens.
 *
 * How to listen:
 * document.addEventListener('on:cart-drawer:before-open', (event) => {
 *   // your code here
 * });
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 6) on:cart-drawer:after-open
 * -------------------------------------------------------------------------------------------------
 * Fires after the cart drawer has finished opening.
 *
 * How to listen:
 * document.addEventListener('on:cart-drawer:after-open', (event) => {
 *   // your code here
 * });
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 7) on:cart-drawer:after-close
 * -------------------------------------------------------------------------------------------------
 * Fires after the cart drawer has finished closing.
 *
 * How to listen:
 * document.addEventListener('on:cart-drawer:after-close', (event) => {
 *   // your code here
 * });
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 8) on:quickbuy:before-open
 * -------------------------------------------------------------------------------------------------
 * Fires before the quick buy drawer opens.
 *
 * How to listen:
 * document.addEventListener('on:quickbuy:before-open', (event) => {
 *   // your code here
 * });
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 9) on:quickbuy:after-open
 * -------------------------------------------------------------------------------------------------
 * Fires after the quick buy drawer has finished opening.
 *
 * How to listen:
 * document.addEventListener('on:quickbuy:after-open', (event) => {
 *   // your code here
 * });
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 10) on:quickbuy:after-close
 * -------------------------------------------------------------------------------------------------
 * Fires after the quick buy drawer has finished closing.
 *
 * How to listen:
 * document.addEventListener('on:quickbuy:after-close', (event) => {
 *   // your code here
 * });
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 11) dispatch:cart-drawer:open
 * -------------------------------------------------------------------------------------------------
 * Opens the cart drawer (if enabled in the Theme Settings).
 *
 * How to trigger:
 * document.dispatchEvent(new CustomEvent('dispatch:cart-drawer:open'));
 *
 * You can optionally pass in a 'detail' object with a property of 'opener', which specifies the DOM
 * element that should be focussed on when the drawer is closed.
 *
 * Example:
 * document.getElementById('header-search').addEventListener('keydown', (evt) => {
 *   if (evt.keyCode === 67) {
 *     evt.preventDefault();
 *     document.dispatchEvent(new CustomEvent('dispatch:cart-drawer:open', {
 *       detail: {
 *         opener: evt.target
 *       }
 *     }));
 *   }
 * });
 *
 * In this example, we attach a keydown listener to the search input in the header. If the user
 * presses the 'c' key, it prevents the default behavior (which would be to type the letter 'c' in
 * the input) and dispatches the 'dispatch:cart-drawer:open' event with a 'detail' object that
 * specifies the search input as the opener. When the cart drawer is closed, focus is returned to
 * the search input.
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 12) dispatch:cart-drawer:refresh
 * -------------------------------------------------------------------------------------------------
 * Refreshes the contents of the cart drawer.
 *
 * This event is useful when you are adding variants to the cart and would like to instruct the
 * theme to re-render the cart drawer.
 *
 * How to trigger:
 * document.dispatchEvent(new CustomEvent('dispatch:cart-drawer:refresh', {
 *   bubbles: true
 * }));
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 13) dispatch:cart-drawer:close
 * -------------------------------------------------------------------------------------------------
 * Closes the cart drawer.
 *
 * How to trigger:
 * document.dispatchEvent(new CustomEvent('dispatch:cart-drawer:close'));
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 14) on:debounced-resize
 * -------------------------------------------------------------------------------------------------
 * Fires when the viewport finishes resizing (debounced to 300ms by default).
 *
 * How to listen:
 * window.addEventListener('on:debounced-resize', (event) => {
 *   // your code here
 * });
 *
 *
 * -------------------------------------------------------------------------------------------------
 * 15) on:breakpoint-change
 * -------------------------------------------------------------------------------------------------
 * Fires when the breakpoint of the viewport changes.
 *
 * Example:
 * window.addEventListener('on:breakpoint-change', (event) => {
 *  if (theme.mediaMatches.md) {
 *   console.log('we are not on mobile');
 *  }
 * });
 *
 *
 *
 * =================================================================================================
 * Web Components
 * =================================================================================================
 *
 * Symmetry utilizes Web Components to the fullest.
 *
 * Web Components are a set of standardized APIs that allow developers to create custom, reusable
 * HTML elements that can be used across different web pages and applications.
 * Web Components consist of three main technologies: Custom Elements, Shadow DOM and HTML
 * Templates.
 *
 * See Mozilla for more: https://developer.mozilla.org/en-US/docs/Web/Web_Components
 *
 *
 *
 =================================================================================================
 * Third-Party JavaScript Dependencies
 * =================================================================================================
 *
 * Symmetry has no third-party JavaScript dependencies.
 *
 *
 * =================================================================================================
 *
 * Have fun! - The Clean Canvas Development Team.
 */

//hide the price if the promo is included
document.addEventListener("DOMContentLoaded", function() {

  
    var observer = new MutationObserver(function(mutationsList, observer) {
        for (var mutation of mutationsList) {
            if (mutation.type === 'childList') {
                var tdfSaleWidget = document.getElementById("tdf_sale_widget");
                if (tdfSaleWidget !== null) {
                    var priceContainers = document.querySelectorAll(".price-container");
                    priceContainers.forEach(function(container) {
                        container.style.display = "none";
                    });
                    applyDiscountStyles();
                    // Disconnect the observer after all necessary operations are done
                    observer.disconnect();
                }
            }
        }
    });
  
});

document.addEventListener('DOMContentLoaded', () => {
    const calculateDiscounts = () => {
        // Get all the product elements
        const productElements = document.querySelectorAll('.price__default');

        productElements.forEach(productElement => {
            // Get the current price and original price elements within this product
            const currentPriceElement = productElement.querySelector('.price__current');
            const originalPriceElement = productElement.querySelector('.price__was');

            // Check if the original price element exists and is empty
            if (originalPriceElement && originalPriceElement.textContent.trim() === '') {
                // Remove the empty original price element
                originalPriceElement.remove();
            }

            // Check if both the current price and original price elements exist and have non-empty values
            if (currentPriceElement && originalPriceElement && currentPriceElement.textContent.trim() !== '' && originalPriceElement.textContent.trim() !== '') {
                // Extract the prices as numbers
                const currentPrice = parseFloat(currentPriceElement.textContent.replace('€', '').replace(',', '.'));
                const originalPrice = parseFloat(originalPriceElement.textContent.replace('€', '').replace(',', '.'));

                // Ensure the prices are valid numbers
                if (!isNaN(currentPrice) && !isNaN(originalPrice) && originalPrice > 0 && currentPrice < originalPrice) {
                    // Calculate the approximate discount percentage
                    const discountPercentage = Math.round(((originalPrice - currentPrice) / originalPrice) * 100);

                    // Check if discount div already exists to avoid duplicates
                    if (!productElement.querySelector('.price__discount')) {
                        // Create a new div element for the discount percentage
                        const discountDiv = document.createElement('div');
                        discountDiv.className = 'price__discount';
                        discountDiv.textContent = `-${discountPercentage}%`;

                        // Insert the discount div before the current price element
                        currentPriceElement.insertAdjacentElement('beforebegin', discountDiv);
                    }
                }
            }
        });
    };

    // Run the discount calculation initially
    calculateDiscounts();

    // Create a MutationObserver to watch for added nodes
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                calculateDiscounts();
            }
        });
    });

    // Start observing the container where products are loaded
    const productContainer = document.querySelector('.product-grid'); // Adjust the selector as needed
    if (productContainer) {
        observer.observe(productContainer, { childList: true, subtree: true });
    }

    // Optional: Add event listener for infinite scroll
    window.addEventListener('scroll', () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            // Trigger discount calculation when user scrolls to the bottom of the page
            calculateDiscounts();
        }
    });

    // Add event listener for pagination buttons
    const addPaginationListeners = () => {
        const paginationButtons = document.querySelectorAll('.pagination__number'); // Adjust the selector as needed
        paginationButtons.forEach(button => {
            button.addEventListener('click', () => {
                // Use a delay to allow new content to load before calculating discounts
                setTimeout(() => {
                    calculateDiscounts();
                }, 1000); // Adjust delay as needed for your specific case
            });
        });
    };

    // Initial call to add pagination listeners
    addPaginationListeners();

    // Re-add pagination listeners if the pagination buttons are dynamically changed
    const paginationContainer = document.querySelector('.pagination-row'); // Adjust the selector as needed
    if (paginationContainer) {
        const paginationObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    addPaginationListeners();
                }
            });
        });
        paginationObserver.observe(paginationContainer, { childList: true, subtree: true });
    }
});



//also trigger the function onload after some seconds
window.onload = function() {
  setTimeout(applyDiscountStyles, 600);
// setTimeout(applyLabelStyles, 100);


   var mediaGallery = document.querySelector('product-form > media-gallery');
    var carouselSlider = document.querySelector('product-form > media-gallery > div > carousel-slider');

    // Add the first class to media-gallery
    mediaGallery.classList.add('media-gallery--layout-carousel-beside');

    // Add the second class to carousel-slider
    carouselSlider.classList.add('sticky-content-container');
   carouselSlider.classList.remove('mobile-only');
};


var idInput = document.querySelector('input[name="id"]');

// MutationObserver to observe changes in product variables
var observer = new MutationObserver(function(mutationsList, observer) {
    for(var mutation of mutationsList) {
        if (mutation.attributeName === 'value') {

           setTimeout(applyDiscountStyles, 400); // Call the function whenever the value attribute changes
        }
    }
});

// Start observing changes in the "value" attribute of the input element
observer.observe(idInput, { attributes: true });



function applyDiscountStyles() {
    var discountContent = document.querySelector('.tdf_discount_content');
    var discountText = discountContent.textContent.toLowerCase();

    var icon = document.querySelector('.tdf_discount_icon');


    if (discountText.includes('silver')) {
        icon.style.backgroundColor = '#bdbdbd';
        icon.style.border = '3px solid #a6a6a6';
        icon.style.setProperty('important', 'true', 'important');
    } else if (discountText.includes('gold')) {
        icon.style.backgroundColor = '#cdbf81';
        icon.style.border = '3px solid #e3dab0';
        icon.style.setProperty('important', 'true', 'important');
    } else if (discountText.includes('platinum')) {
        icon.style.backgroundColor = '#a0b2c6';
        icon.style.border = '3px solid #cad6e4';
        icon.style.setProperty('important', 'true', 'important');
    }
}

//not used - function to change color of the labels on the product
function applyLabelStyles() {
    var labels = document.querySelectorAll('.tdf_label_v'); 

    labels.forEach(function(label) { 
        var labelText = label.textContent.toLowerCase(); 

        if (labelText.includes('silver')) {
            label.style.background = '#bdbdbd'; 
            label.classList.add('applied-style'); 
        } else if (labelText.includes('gold')) {
            label.style.background = '#cdbf81'; 
            label.classList.add('applied-style'); 
        } else if (labelText.includes('platinum')) {
            label.style.background = '#b8b8b8'; 
            label.classList.add('applied-style'); 
        }
    });
}
